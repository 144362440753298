import BaseInstance from "../base.instance";
import {
  UNIT_UTAMA,
  UNIT_KERJA_ALL,
  UNIT_KERJA_2,
  UNIT_KERJA_3,
  UNIT_KERJA_4,
  IMAGE_GROUP,
  DIGITAL_LIST,
  PRESENSI_PEGAWAI_ACTIVE,
  PENDIDIKAN_LIST,
  STUDI_LIST,
  GOLONGAN_LIST,
  ESELON_LIST,
  JABATAN_LIST,
  PANGKAT_LIST,
  KELAS_LIST,
  PROVINCE_LIST,
  CITY_LIST,
  DISTRICT_LIST,
  VILLAGE_LIST,
  PRESENSI_NOTE_LIST
} from "../constants";

const PublicService = {
  // Unit Kerja
  getUnitUtamaList() {
    return BaseInstance.query(UNIT_UTAMA);
  },
  getUnitKerjaList() {
    return BaseInstance.fetch(UNIT_KERJA_ALL, 0);
  },
  getUnitKerjaList2(id) {
    return BaseInstance.fetch(UNIT_KERJA_2, id);
  },
  getUnitKerjaList3(id) {
    return BaseInstance.fetch(UNIT_KERJA_3, id);
  },
  getUnitKerjaList4(id) {
    return BaseInstance.fetch(UNIT_KERJA_4, id);
  },
  getImageGroup(params) {
    return BaseInstance.query(IMAGE_GROUP, { params });
  },
  getDigitalList(id) {
    return BaseInstance.fetch(DIGITAL_LIST, id);
  },
  getUserSearchList(data) {
    return BaseInstance.post(PRESENSI_PEGAWAI_ACTIVE, data);
  },
  unitutamaGetList() {
    return BaseInstance.query(UNIT_UTAMA);
  },
  pendidikanGetList(params) {
    return BaseInstance.query(PENDIDIKAN_LIST, { params });
  },
  studiGetList(params) {
    return BaseInstance.query(STUDI_LIST, { params });
  },
  golonganGetList(params) {
    return BaseInstance.query(GOLONGAN_LIST, { params });
  },
  eselonGetList(params) {
    return BaseInstance.query(ESELON_LIST, { params });
  },
  jabatanGetList(params) {
    return BaseInstance.query(JABATAN_LIST, { params });
  },
  pangkatGetList(params) {
    return BaseInstance.query(PANGKAT_LIST, { params });
  },
  kelasGetList(params) {
    return BaseInstance.query(KELAS_LIST, { params });
  },
  // Address
  provinsiGetList(params) {
    return BaseInstance.query(PROVINCE_LIST, { params });
  },
  kabupatenGetList(params) {
    return BaseInstance.query(CITY_LIST, { params });
  },
  kecamatanGetList(params) {
    return BaseInstance.query(DISTRICT_LIST, { params });
  },
  kelurahanGetList(params) {
    return BaseInstance.query(VILLAGE_LIST, { params });
  },
  jenisCatatanGetList(params) {
    return BaseInstance.query(PRESENSI_NOTE_LIST, { params });
  }
};

export default PublicService;
