<template>
  <v-card flat>
    <v-row>
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="3"
        sm="12"
        xs="12"
        v-if="!isUpdate && !isApprove"
      >
        <v-row justify="center" class="mt-10 mb-3">
          <v-avatar size="300" rounded>
            <v-img :src="personal.photo" contain />
          </v-avatar>
        </v-row>
        <p
          class="mt-1 text-center text-capitalize subtitle-2 primary-color hover-primary"
        >
          Ubah Foto
        </p>
      </v-col>
      <v-col
        cols="12"
        :xl="isUpdate || isApprove ? '12' : '9'"
        :lg="isUpdate || isApprove ? '12' : '9'"
        :md="isUpdate || isApprove ? '12' : '9'"
        sm="12"
        xs="12"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Kelahiran :</td>
                      <td>
                        <v-card-actions v-if="isUpdate" class="pa-0">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="personal.tempat_lahir"
                          ></v-text-field>
                          <v-text-field
                            class="ml-1"
                            type="date"
                            outlined
                            dense
                            hide-details
                            v-model="personal.tanggal_lahir"
                          ></v-text-field>
                        </v-card-actions>
                        <span v-else
                          >{{ personal.tempat_lahir }},
                          {{ personal.tanggal_lahir | date }}</span
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Agama :</td>
                      <td>
                        <v-select
                          v-if="isUpdate"
                          :items="agamaList"
                          outlined
                          dense
                          hide-details
                          v-model="personal.agama"
                        ></v-select>
                        <span v-else>{{ personal.agama }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Alamat :</td>
                      <td>
                        <v-textarea
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="personal.alamat"
                          rows="3"
                        ></v-textarea>
                        <span v-else>{{ personal.alamat }}</span>
                      </td>
                    </tr>
                    <tr v-if="!isUpdate">
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Provinsi :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="provinsiList"
                          outlined
                          dense
                          hide-details
                          v-model="personal.provinsi"
                          :loading="provinsiLoading"
                          item-text="name"
                          item-value="kode_wilayah"
                          return-object
                          @change="addressChange('provinsi')"
                        ></v-autocomplete>
                        <span v-else>{{
                          personal.provinsi && personal.provinsi.name
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Kelurahan :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="kelurahanList"
                          :loading="kelurahanLoading"
                          outlined
                          dense
                          hide-details
                          v-model="personal.kelurahan"
                          item-text="name"
                          item-value="kode_wilayah"
                          return-object
                          @change="addressChange('kelurahan')"
                        ></v-autocomplete>
                        <span v-else>{{
                          personal.kelurahan && personal.kelurahan.name
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Usia :</td>
                      <td>
                        <span>{{ personal.usia }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Kabupaten/Kota :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="kabupatenList"
                          outlined
                          dense
                          hide-details
                          v-model="personal.kabupaten"
                          :loading="kabupatenLoading"
                          item-text="name"
                          item-value="kode_wilayah"
                          return-object
                          @change="addressChange('kabupaten')"
                        ></v-autocomplete>
                        <span v-else>{{
                          personal.kabupaten && personal.kabupaten.name
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">RT/RW :</td>
                      <td>
                        <v-card-actions v-if="isUpdate" class="pa-0">
                          <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="personal.rt"
                          ></v-text-field>
                          <v-text-field
                            class="ml-1"
                            outlined
                            dense
                            hide-details
                            v-model="personal.rw"
                          ></v-text-field>
                        </v-card-actions>
                        <span v-else
                          >{{ personal.rt }} / {{ personal.rw }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="4" lg="4" md="4" sm="12" xs="12" class="px-0">
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Jenis Kelamin :</td>
                      <td>
                        <v-select
                          v-if="isUpdate"
                          :items="kelaminList"
                          outlined
                          dense
                          hide-details
                          item-text="label"
                          item-value="value"
                          v-model="personal.kelamin"
                        ></v-select>
                        <span v-else>{{
                          personal.kelamin
                            ? personal.kelamin === "1"
                              ? "Pria"
                              : "Wanita"
                            : ""
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Status Perkawinan :</td>
                      <td>
                        <v-select
                          v-if="isUpdate"
                          :items="statusDiriList"
                          outlined
                          dense
                          hide-details
                          v-model="personal.status_diri"
                        ></v-select>
                        <span v-else>{{ personal.status_diri }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Kecamatan :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="kecamatanList"
                          outlined
                          dense
                          hide-details
                          v-model="personal.kecamatan"
                          :loading="kecamatanLoading"
                          item-text="name"
                          item-value="kode_wilayah"
                          return-object
                          @change="addressChange('kecamatan')"
                        ></v-autocomplete>
                        <span v-else>{{
                          personal.kecamatan && personal.kecamatan.name
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Kode Pos :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="personal.kode_pos"
                        ></v-text-field>
                        <span v-else>{{ personal.kode_pos }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="px-0">
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Pendidikan
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">Jenjang :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="pendidikanList"
                          :loading="loadingPendidikan"
                          outlined
                          dense
                          hide-details
                          :disabled="!$_.isEmpty(personal.pendidikan.jenjang)"
                          v-model="personal.pendidikan.jenjang"
                          item-text="nama_pend"
                          item-value="kode_pend"
                          return-object
                        ></v-autocomplete>
                        <span v-else>{{
                          personal.pendidikan.jenjang &&
                            personal.pendidikan.jenjang.nama_pend
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tingkatan :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="levels"
                          :loading="loadingLevel"
                          outlined
                          dense
                          hide-details
                          :disabled="!$_.isEmpty(personal.pendidikan.level)"
                          v-model="personal.pendidikan.level"
                          item-text="deskripsi_level"
                          item-value="pend_level_id"
                          return-object
                        ></v-autocomplete>
                        <span v-else>{{
                          personal.pendidikan.level &&
                            personal.pendidikan.level.deskripsi_level
                        }}</span>
                      </td>
                    </tr>

                    <tr>
                      <td class="font-weight-bold">Bidang Studi :</td>
                      <td>
                        <v-autocomplete
                          v-if="isUpdate"
                          :items="studiList"
                          :loading="loadingStudi"
                          outlined
                          dense
                          hide-details
                          :disabled="
                            !$_.isEmpty(personal.pendidikan.bidang_studi)
                          "
                          v-model="personal.pendidikan.bidang_studi"
                          item-text="nama_studi"
                          item-value="kode_studi"
                          return-object
                        ></v-autocomplete>
                        <span v-else>{{
                          personal.pendidikan.bidang_studi &&
                            personal.pendidikan.bidang_studi.nama_studi
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Nama Sekolah :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="personal.pendidikan.nama_sekolah"
                        ></v-text-field>
                        <span v-else>{{
                          personal.pendidikan.nama_sekolah
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Tahun Lulus :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="personal.pendidikan.thn_lulus"
                        ></v-text-field>
                        <span v-else>{{ personal.pendidikan.thn_lulus }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="px-0">
              <p class="ml-3 subtitle-1 font-weight-bold headline-color">
                Informasi Lain
              </p>
              <v-simple-table id="table-custom">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">No. KTP :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.no_KTP"
                        ></v-text-field>
                        <span v-else>{{ personal.lainnya.no_KTP }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">NPWP :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.NPWP"
                        ></v-text-field>
                        <span v-else>{{ personal.lainnya.NPWP }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">TASPEN :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.no_taspen"
                        ></v-text-field>
                        <span v-else>{{ personal.lainnya.no_taspen }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">No. HP :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.hp_number"
                        ></v-text-field>
                        <span v-else>{{ personal.lainnya.hp_number }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">Email :</td>
                      <td>
                        <v-text-field
                          v-if="isUpdate"
                          outlined
                          dense
                          hide-details
                          v-model="personal.lainnya.email_address"
                        ></v-text-field>
                        <span v-else>{{ personal.lainnya.email_address }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapEmployeeRootField } from "@/store/helpers";
import PublicService from "@/services/resources/public.service";
import DataUtamaService from "@/services/resources/data.utama.service";

export default {
  props: {
    isUpdate: {
      type: Boolean,
      required: false,
      default: false
    },
    isApprove: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loadingPendidikan: false,
      loadingStudi: false,
      loadingLevel: false,
      kelurahanLoading: false,
      kecamatanLoading: false,
      kabupatenLoading: false,
      provinsiLoading: false,
      kelaminList: [
        {
          label: "Pria",
          value: "1"
        },
        {
          label: "Wanita",
          value: "2"
        }
      ],
      statusDiriList: ["Belum Kawin", "Kawin", "Duda", "Janda"],
      agamaList: ["Islam", "Katolik", "Protestan", "Hindu", "Budha"],
      pendidikanList: [],
      studiList: [],
      kelurahanList: [],
      kecamatanList: [],
      kabupatenList: [],
      provinsiList: [],
      levels: []
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal"
    }),
    pendidikanForm() {
      return this.personal.pendidikan.jenjang;
    }
  },
  watch: {
    pendidikanForm() {
      this.getStudiList();
    }
  },
  methods: {
    addressChange(type = "provinsi") {
      let nextType = "kabupaten";
      if (type == "provinsi") {
        this.kabupatenList = [];
        this.kecamatanList = [];
        this.kelurahanList = [];
        nextType = "kabupaten";
      } else if (type == "kabupaten") {
        this.kecamatanList = [];
        this.kelurahanList = [];
        nextType = "kecamatan";
      } else if (type == "kecamatan") {
        this.kelurahanList = [];
        nextType = "kelurahan";
      }
      this.getAddressList(nextType);
    },
    handleToggle() {
      this.$emit("toggle");
    },
    // Service
    async getPendidikanLevelLOV() {
      try {
        this.loadingLevel = true;
        await DataUtamaService.getPendidikanLevelLOV({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.levels = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingLevel = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getPendidikanList() {
      try {
        this.loadingPendidikan = true;
        await PublicService.pendidikanGetList({
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.pendidikanList = data;
              this.getStudiList();
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingPendidikan = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getStudiList() {
      try {
        this.loadingStudi = true;
        await PublicService.studiGetList({
          kode_pend: this.personal.pendidikan.jenjang?.kode_pend,
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.studiList = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loadingStudi = false));
      } catch (err) {
        console.error(err);
      }
    },
    async getAddressList(type = "provinsi") {
      let kode_wilayah = "";
      if (type == "provinsi") {
        kode_wilayah = "000000";
      } else if (type == "kabupaten") {
        kode_wilayah = this.personal.provinsi?.kode_wilayah;
      } else if (type == "kecamatan") {
        kode_wilayah = this.personal.kabupaten?.kode_wilayah;
      } else if (type == "kelurahan") {
        kode_wilayah = this.personal.kecamatan?.kode_wilayah;
      }
      try {
        this[type + "Loading"] = true;
        await PublicService[type + "GetList"]({
          kode_wilayah,
          search: ""
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this[type + "List"] = data;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this[type + "Loading"] = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.getPendidikanList();
    this.getAddressList("provinsi");
    this.getAddressList("kabupaten");
    this.getAddressList("kecamatan");
    this.getAddressList("kelurahan");
    this.getPendidikanLevelLOV();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.675rem;
  }
}
#table-custom .v-input {
  font-size: 0.675rem;
}
</style>
